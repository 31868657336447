import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createClient } from '@supabase/supabase-js';
import { SessionContextProvider } from '@supabase/auth-helpers-react';

const supabase = createClient(
  "https://bxvvgybmjfeyzipodeeb.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJ4dnZneWJtamZleXppcG9kZWViIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDc2MjYzMjIsImV4cCI6MjAyMzIwMjMyMn0.tbTtS96NWKCoOZxvNw4goVreM3YEfAPgGoLANk3tou8"
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <SessionContextProvider supabaseClient={supabase}>
     <App />
    </SessionContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
