export const homeText = [
    {
        text: `Enter customer information, search for active customers, and schedule appointments.${'\n'}To get started, select the menu options in the left sidebar menu.`
    }
];

export const accordionText = [
    {
        icon: "Calendar",
        name: "Your Calendars",
        info: [
            {
                id: 0,
                description: "ipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumloremipsumlorem"
            }
        ]
    }
]
